import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import * as style from './Header.module.scss';
import Hamburger from '../Hamburger.js/Hamburger';
import Menu from '../Menu/Menu';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const checkIfCloseMenu = () => {
    if (typeof window === 'undefined') return;
    if (window.innerWidth > 768) setIsMenuOpen(false);
  };

  const disableScroll = () => {
    if (!document.body.classList.contains('noScroll'))
      document.body.classList.add('noScroll');
  };

  const enableScroll = () => {
    document.body.classList.remove('noScroll');
  };

  const toggleScroll = (isOpen) => {
    if (isOpen) disableScroll();
    else enableScroll();
    return isOpen;
  };

  const closeMenu = () => {
    enableScroll();
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', checkIfCloseMenu);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      sanityMetaAndLinks {
        instagram_url
      }
    }
  `);

  const instaURL = data.sanityMetaAndLinks.instagram_url;

  return (
    <>
      <header className={style.container} id="header">
        <Link className={style.homeLink} to="/" onClick={closeMenu}>
          Ottone Photography
        </Link>

        <Hamburger
          isOpen={isMenuOpen}
          handleClick={() => setIsMenuOpen((current) => toggleScroll(!current))}
        />

        <div className={style.links}>
          <Link className={style.link} to="/about">
            About
          </Link>
          <Link className={style.link} to="/folio">
            Folio
          </Link>
          <Link className={style.link} to="/contact">
            Contact
          </Link>
          <a
            className={style.link}
            href="https://ottonephotography.bigcartel.com/"
            target="_blank"
            rel="noreferrer"
          >
            Shop
          </a>
          <a
            className={style.link}
            href={instaURL}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
      </header>
      <div className={style.menuAnchor}>
        <div
          className={[style.menuWrapper, isMenuOpen ? style.open : null].join(
            ' '
          )}
          style={{ minHeight: `calc(100vh - var(--headerHeight)` }}
        >
          <Menu isOpen={isMenuOpen} closeMenu={closeMenu} />
        </div>
      </div>
    </>
  );
};

Header.displayName = 'Header';

export default Header;
